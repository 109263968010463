












































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import TasqDetailsMixin from '@/components/tasqsCommon/TasqDetailsMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';
import WellSignalsView from './WellSignalsView.vue';

@Component
export default class
TasqsDetailV2 extends mixins(TasqDetailsMixin) {




  handleScroll() {




    (this.$refs.myChildRef as WellSignalsView).handleScroll();

    // (this.$refs.myChildRefProd as TasqLightningProductionChart).reloadChartOnScroll();
    
    // (this.$refs.myPlungerChartRef as TasqPlungerLightningChart).reloadChartOnScroll();

      // if(this.timer !== null) {
      //     clearTimeout(this.timer);        
      // }
      // this.timer = setTimeout(function() {
      //       //  User stopped scrolling - we can handle workarounds here if need be
      // }, 150);

  }


  @Watch('$route.params.id', { immediate: true })
  async onTasqRouteIdChange(id: string) {

    const { wellName } = this.$route.query;
    if (id) {
      this.fetchTasq();
      // this.getPredictionList();
      this.padWellsSkipToBeginning();
    } else {
      tasqsListModule.setActiveTasq('');
    }
    this.multiTasqType = '';
  }
}
